import { Injectable } from '@angular/core';
import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationExtras,
  ParamMap,
  Router,
} from '@angular/router';
import { BtnService } from 'core-components';
import {
  Observable,
  ReplaySubject,
  combineLatest,
  map,
  of,
  switchMap,
  take,
  takeUntil,
  tap,
} from 'rxjs';
import {
  BtnModel,
  DateHelperService,
  GenericItem,
  GenericItemsHelper,
  MessagingService,
  TranslateService,
  WithUnsubscribe,
  phoneValidator,
  shareReplayUntil,
} from 'shared';
import { LanguageHelper } from 'wr-components';
import {
  AvailabilityDay,
  CandidateTargetField,
  CreateNewAppointmentResponse,
  GetBookingInformationResponse,
  NewAppointmentRequest,
  QuestionType,
} from '../api/models';
import { CalendarEventsService } from '../api/services';
import {
  BookingFormControls,
  BookingFormGroup,
  CalendarDay,
  CalendarWeek,
  QuestionModelControls,
  QuestionModelFormGroup,
  State,
  UpdateTimeMessage,
} from '../models';

@Injectable()
export class CalendarEventService extends WithUnsubscribe() {
  private _params$: ReplaySubject<ParamMap> = new ReplaySubject<ParamMap>(1);
  private _month$: ReplaySubject<Date> = new ReplaySubject(1);
  private _currentMonth: Date;
  private _selectedDate$: ReplaySubject<Date> = new ReplaySubject(1);
  private _step$: ReplaySubject<number> = new ReplaySubject(1);
  private updateTimeMessage$ = this._messagingService
    .of(UpdateTimeMessage)
    .pipe(shareReplayUntil(this.unsubscribe$));
  private eventModelId$ = this.getEventModelId$().pipe(
    shareReplayUntil(this.unsubscribe$)
  );
  private eventId$ = this.getEventId$().pipe(
    shareReplayUntil(this.unsubscribe$)
  );
  private userInfo$ = this.getUserPart$().pipe(
    shareReplayUntil(this.unsubscribe$)
  );
  private informations$: Observable<GetBookingInformationResponse> =
    this.getInformations$().pipe(shareReplayUntil(this.unsubscribe$));
  private firstMonthCalendar$: Observable<CalendarWeek[]> =
    this.getFirstMonthCalendar$();
  private secondMonthCalendar$: Observable<CalendarWeek[]> =
    this.getSecondMonthCalendar$();
  private selectedDate$ = this._selectedDate$.asObservable();
  private availabilities$ = this.getAvailabilities$().pipe(
    shareReplayUntil(this.unsubscribe$)
  );

  private form$ = this.getForm$().pipe(shareReplayUntil(this.unsubscribe$));
  private btnList$ = this.getBtnList$();

  public state$: Observable<State> = this.getState$();
  public isLoading: boolean;
  constructor(
    private readonly _service: CalendarEventsService,
    private readonly _router: Router,
    private readonly _fb: FormBuilder,
    private readonly _dateService: DateHelperService,
    private readonly _btnService: BtnService,
    private readonly _messagingService: MessagingService,
    private readonly _translateService: TranslateService,
    private readonly _giService: GenericItemsHelper,
    private readonly _languageHelper: LanguageHelper
  ) {
    super();
    const today = new Date();
    today.setHours(0);
    today.setMinutes(0);
    today.setSeconds(0);
    today.setMilliseconds(0);
    this._currentMonth = new Date(today.getFullYear(), today.getMonth(), 1);
    this._month$.next(this._currentMonth);
    this._selectedDate$.next(today);
    this._step$.next(0);
    this._messagingService.publish(new UpdateTimeMessage(null));
    this.setUpUpdateTime();
    this.setUpUpdateDate();
  }

  public updateRouteParams(route: ActivatedRoute) {
    route.paramMap.subscribe((params) => this._params$.next(params));
  }

  public updateSelectedDate(day: CalendarDay) {
    this._messagingService.publish(new UpdateTimeMessage(null));
    this._selectedDate$.next(day.date);
  }

  private getState$(): Observable<State> {
    return combineLatest([
      this.informations$,
      this.firstMonthCalendar$,
      this.secondMonthCalendar$,
      this._month$,
      this.selectedDate$,
      this.availabilities$,
      this._step$,
      this.form$,
      this.btnList$,
    ]).pipe(
      map(
        ([
          informations,
          firstMonthCalendar,
          secondMonthCalendar,
          firstMonthDate,
          selectedDate,
          availabilities,
          currentStep,
          form,
          btnList,
        ]) => ({
          informations,
          firstMonth: firstMonthCalendar,
          secondMonth: secondMonthCalendar,
          firstMonthDate,
          secondMonthDate: new Date(
            firstMonthDate.getFullYear(),
            firstMonthDate.getMonth() + 1,
            1
          ),
          selectedDate,
          selectedDateTime: selectedDate.getTime(),
          selectedTime: form.value.time,
          availabilities,
          currentStep,
          form,
          btnList,
        })
      )
    );
  }

  private getFirstMonthCalendar$(): Observable<CalendarWeek[]> {
    return combineLatest([this.informations$, this._month$]).pipe(
      map(([informations, month]) =>
        this.transformInformationsToMonth(informations, month)
      )
    );
  }
  private getSecondMonthCalendar$(): Observable<CalendarWeek[]> {
    return combineLatest([this.informations$, this._month$]).pipe(
      map(([informations, month]) =>
        this.transformInformationsToMonth(
          informations,
          new Date(month.getFullYear(), month.getMonth() + 1, 1)
        )
      )
    );
  }

  private transformInformationsToMonth(
    informations: GetBookingInformationResponse,
    month: Date
  ): CalendarWeek[] {
    const weeks: CalendarWeek[] = [];
    var currentFirstDay = month;
    var currentWeek: CalendarWeek = {
      days: [],
    };

    // Get all days that could be available
    const daysAvailabilities = Array(7).fill(false);
    var todayThreshold = new Date();
    todayThreshold.setHours(23);
    todayThreshold.setMinutes(59);
    todayThreshold.setSeconds(59);
    todayThreshold = this.addDay(todayThreshold, -1);
    informations.availabilities?.forEach((availability) => {
      if (availability.days?.includes(AvailabilityDay.MONDAY)) {
        daysAvailabilities[0] = true;
      }
      if (availability.days?.includes(AvailabilityDay.TUESDAY)) {
        daysAvailabilities[1] = true;
      }
      if (availability.days?.includes(AvailabilityDay.WEDNESDAY)) {
        daysAvailabilities[2] = true;
      }
      if (availability.days?.includes(AvailabilityDay.THURSDAY)) {
        daysAvailabilities[3] = true;
      }
      if (availability.days?.includes(AvailabilityDay.FRIDAY)) {
        daysAvailabilities[4] = true;
      }
      if (availability.days?.includes(AvailabilityDay.SATURDAY)) {
        daysAvailabilities[5] = true;
      }
      if (availability.days?.includes(AvailabilityDay.SUNDAY)) {
        daysAvailabilities[6] = true;
      }
      if (availability.days?.includes(AvailabilityDay.WEEKDAYS)) {
        daysAvailabilities[0] = true;
        daysAvailabilities[1] = true;
        daysAvailabilities[2] = true;
        daysAvailabilities[3] = true;
        daysAvailabilities[4] = true;
      }
      if (availability.days?.includes(AvailabilityDay.WEEKENDS)) {
        daysAvailabilities[5] = true;
        daysAvailabilities[6] = true;
      }
      if (availability.days?.includes(AvailabilityDay.ALL_DAYS)) {
        for (let index = 0; index < daysAvailabilities.length; index++) {
          daysAvailabilities[index] = true;
        }
      }
    });
    if (currentFirstDay.getDay() !== 1) {
      for (let index = (currentFirstDay.getDay() + 6) % 7; index > 0; index--) {
        const date = this.addDay(currentFirstDay, -index);
        currentWeek.days.push({
          date,
          available: daysAvailabilities[(date.getDay() + 6) % 7],
          otherMonth: true,
          isPast: date.getTime() < todayThreshold.getTime(),
          time: date.getTime(),
        });
      }
    }

    for (
      let index = 0;
      index < 7 - ((currentFirstDay.getDay() + 6) % 7);
      index++
    ) {
      const date = this.addDay(currentFirstDay, index);

      currentWeek.days.push({
        date,
        available: daysAvailabilities[(date.getDay() + 6) % 7],
        otherMonth: false,
        isPast: date.getTime() < todayThreshold.getTime(),
        time: date.getTime(),
      });
    }
    weeks.push(currentWeek);
    const daysToAdd =
      currentFirstDay.getDay() === 1
        ? 7
        : (7 - currentFirstDay.getDay() + 1) % 7;
    currentFirstDay = this.addDay(currentFirstDay, daysToAdd);
    do {
      currentWeek = {
        days: [],
      };
      for (let index = 0; index <= 7 - currentFirstDay.getDay(); index++) {
        const date = this.addDay(currentFirstDay, index);
        currentWeek.days.push({
          date,
          available: daysAvailabilities[(date.getDay() + 6) % 7],
          otherMonth: date.getMonth() !== currentFirstDay.getMonth(),
          isPast: date.getTime() < todayThreshold.getTime(),
          time: date.getTime(),
        });
      }
      weeks.push(currentWeek);

      currentFirstDay = this.addDay(currentFirstDay, 7);
    } while (currentFirstDay.getDate() > 7);
    return weeks;
  }

  private getEventModelId$(): Observable<string> {
    return this._params$.pipe(map((params) => params.get('eventSlug')));
  }
  private getEventId$(): Observable<string> {
    return this._params$.pipe(map((params) => params.get('activityLink')));
  }
  private getUserPart$(): Observable<string> {
    return this._params$.pipe(map((params) => params.get('userInfo')));
  }
  private getInformations$(): Observable<GetBookingInformationResponse> {
    return combineLatest([
      this.userInfo$,
      this.eventId$,
      this.eventModelId$,
    ]).pipe(
      tap((_) => (this.isLoading = true)),
      take(1),
      switchMap(([userInfo, eventId, eventModelId]) => {
        const args: CalendarEventsService.GetBookingInformationsParams = {
          user: userInfo,
          eventPart: eventId ?? '',
          eventModelUrl: eventModelId,
        };
        return this._service.GetBookingInformations(args);
      }),
      tap((_) => (this.isLoading = false))
    );
  }

  private getAvailabilities$(): Observable<Date[]> {
    return combineLatest([
      this.informations$,
      this.selectedDate$,
      this.eventId$,
    ]).pipe(
      switchMap(([informations, date, eventId]) => {
        return this._service.GetDayAvailabilities({
          id: informations.eventId ?? 0,
          date: date.toDateString(),
          eventId,
        });
      }),
      map((response) => {
        if (response.availabilities) {
          return response.availabilities.map((date) => new Date(date));
        } else {
          return [];
        }
      })
    );
  }
  private getForm$(): Observable<BookingFormGroup> {
    return this.informations$.pipe(
      map((informations) => {
        if (!informations) {
          return null;
        }

        this._translateService.setCurrentLang(
          this._languageHelper.convertToLang(informations.language)
        );
        const today = new Date();
        const questions: QuestionModelFormGroup[] = [];
        informations?.questions?.forEach((question) => {
          const questionControls: QuestionModelControls = {
            id: this._fb.control(question.id),
            order: this._fb.control(question.sortOrder),
            required: this._fb.control(question.required),
            type: this._fb.control(question.type),
            name: this._fb.control(question.name),
            candidateTargetField: this._fb.control(question.targetField),
          };

          const validators: ValidatorFn[] = [];
          switch (question.type) {
            case QuestionType.TEXT:
            case QuestionType.NUMBER:
              switch (question.targetField) {
                case CandidateTargetField.PHONE:
                  validators.push(phoneValidator());
                  break;
                case CandidateTargetField.EMAIL:
                  validators.push(Validators.email);
                  break;
              }
              questionControls.value = this._fb.control(
                question.defaultValue,
                validators
              );
              break;
            case QuestionType.QCU:
            case QuestionType.QCM:
            case QuestionType.QCMTAG:
            case QuestionType.LANGUAGE:
              questionControls.choices = this._fb.control(
                question.choices
                  .sort((a, b) => a.order - b.order)
                  .map(
                    (choice) =>
                      ({
                        id: choice.id,
                        label: choice.label,
                        item: choice.value,
                        isSelected: choice.value === question.defaultValue,
                      } as GenericItem)
                  )
              );
              questionControls.value = this._fb.control(
                questionControls.choices.value.find(
                  (choice) => choice.item === question.defaultValue
                )
              );
              break;
            case QuestionType.YES_NO:
              questionControls.choices = this._fb.control([
                this._giService.getYesOrNo(true),
                this._giService.getYesOrNo(false),
              ]);
              questionControls.value = this._fb.control(
                questionControls.choices.value.find(
                  (choice) => choice.id === question.defaultValue
                )
              );
              break;
            default:
              break;
          }
          questions.push(this._fb.group(questionControls));
        });
        const controls: BookingFormControls = {
          calendarEventId: this._fb.control(informations.eventId),
          activityLinkId: this._fb.control(informations.activityLinkId),
          eventTitle: this._fb.control(informations.eventTitle),
          eventDescription: this._fb.control(informations.eventDescription),
          date: this._fb.control(
            this._dateService.convertDateToNgbDateStruct(today)
          ),
          time: this._fb.control(null),
          questions: this._fb.array(questions),
        };
        return this._fb.group(controls);
      })
    );
  }
  private setUpUpdateTime() {
    combineLatest([this.form$, this.updateTimeMessage$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([form, updateTimeMessage]) =>
        form.patchValue({ time: updateTimeMessage.time })
      );
  }

  private setUpUpdateDate() {
    combineLatest([this.form$, this.selectedDate$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(([form, date]) => {
        form.patchValue({
          date: this._dateService.convertDateToNgbDateStruct(date),
        });
      });
  }
  nextMonth() {
    this._currentMonth = new Date(
      this._currentMonth.getFullYear(),
      this._currentMonth.getMonth() + 1,
      1
    );
    this._month$.next(this._currentMonth);
  }
  previousMonth() {
    this._currentMonth = new Date(
      this._currentMonth.getFullYear(),
      this._currentMonth.getMonth() - 1,
      1
    );
    this._month$.next(this._currentMonth);
  }

  private addDay(date: Date, daysToAdd: number): Date {
    const day = new Date(date);
    day.setDate(date.getDate() + daysToAdd);
    return day;
  }

  changeStep(stepValue: number) {
    this._step$.next(stepValue);
  }

  private getBtnList$(): Observable<BtnModel[]> {
    return combineLatest([this._step$, this.updateTimeMessage$]).pipe(
      map(([step, msg]) => {
        if (step === 0) {
          if (msg.time && msg.time.hour != null && msg.time.minute != null) {
            return [
              this._btnService.getNextBtn({
                action: () => this.changeStep(1),
              }),
            ];
          }
        } else {
          return [
            this._btnService.getBackBtn({
              action: () => this.changeStep(0),
            }),
            this._btnService.getSaveBtn({
              label: this._translateService.getTranslationValue(
                'Calendar.blkCalendar.btnSave'
              ),
              action$: () => this.save$(),
            }),
          ];
        }
        return [];
      })
    );
  }

  private getPayload(form: BookingFormGroup): NewAppointmentRequest {
    return {
      activityLinkId: form.value.activityLinkId,
      selectedDate: this._dateService.toString(
        form.value.date,
        form.value.time
      ),
      bookQuestionAnswers: form.value.questions.map((question) => ({
        candidateTargetField: question.candidateTargetField,
        questionId: question.id,
        type: question.type,
        value:
          typeof question.value === 'string'
            ? question.value
            : question.value.item,
      })),
    };
  }

  public save$(): Observable<CreateNewAppointmentResponse | null> {
    return this.form$.pipe(
      switchMap((form) => {
        form.markAllAsTouched();
        if (form.valid) {
          const payload = this.getPayload(form);
          return this._service.NewAppointment({
            id: form.value.calendarEventId,
            body: payload,
          });
        }
        return of(null);
      }),
      tap((result) => {
        if (result) {
          const navigationExtras: NavigationExtras = {
            state: {
              newAppointment: result,
            },
          };
          this._router.navigate(['success'], navigationExtras);
        }
      })
    );
  }
}
